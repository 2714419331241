import React, { useState, useEffect } from 'react';
import styles from './Checkout.module.css';
import { checkPayment } from '../../../services/StripeService';
import { usePageContext } from '../../../Context';

export default function PaymentSuccess() {
  const [message, setMessage] = useState('');
  const [messageTag, setMessageTag] = useState('');
  const [emailTextTag, setEmailTextTag] = useState('');
  const { getTag, setLoading, locale } = usePageContext();

  const checkPaymentStatus = async () => {
    setLoading(true);
    localStorage.removeItem('cart');
    const paymentIntentID = new URLSearchParams(window.location.search).get('payment_intent');
    if (!paymentIntentID) return;
    try {
      const res = await checkPayment(paymentIntentID, locale);
      switch (res.status) {
      case 'succeeded':
        setMessageTag('paymentSucceded');
        setEmailTextTag('emailText');
        setLoading(false);
        break;
      case 'processing':
        setMessageTag('processing');
        setLoading(false);
        break;
      case 'requires_payment_method':
        setMessageTag('requires_payment_method');
        setLoading(false);
        break;
      default:
        setMessage(res.status);
        setLoading(false);
        break;
      }
    } catch (error) {
      setMessageTag('somethingwentwrong');
      setLoading(false);
    }
  };

  useEffect(() => {
    checkPaymentStatus();
  }, []);

  return (
    <div className={styles.statusWrapper}>
      <div className={styles.cardBox}>
        <h2>{messageTag ? getTag(messageTag) : message}</h2>
        <h3>{getTag(emailTextTag)}</h3>
      </div>
    </div>
  );
}
