import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import {
  Grid,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  RadioGroup,
  Radio,
  Modal,
  Box,
  Checkbox
} from '@mui/material';
import MuiPhoneNumber from 'mui-phone-number';
import { usePageContext } from '../../Context';
import styles from './Cart.module.css';
import { getCartPictures } from '../../services/PictureService';
import getRentalConditions from '../../services/LangTagService';
import { getCartItems } from '../../services/ProductService';
import { addBooking } from '../../services/BookingService';
import { identify } from '../../services/HighlightService';
import {
  cartPictures,
  cartItem,
  PaymentMethods,
  personInfo
} from '../../types';
import { deleteOngoing } from '../../services/StripeService';

export default function Cart() {
  const {
    getTag,
    setLoading,
    snackBarEvent,
    locale,
    loading
  } = usePageContext();
  const [items, setItems] = useState<any>({});
  const [firstName, setFirstName] = useState<string>('');
  const [accepted, setAccepted] = useState<boolean>(false);
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [paymentPictures, setPaymentPictures] = useState<cartPictures>();
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethods | undefined>(undefined);
  const navigate = useNavigate();
  const [rentalConditions, setRentalConditions] = useState<any>();

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const fetchData = async (cart: any) => {
    setLoading(true);
    try {
      const payPictures = await getCartPictures();
      setPaymentPictures(payPictures);
    } catch (error) {
      snackBarEvent('error', getTag('unabletofetchpictures'));
    }
    try {
      const cartItems = await getCartItems(cart);
      setItems(cartItems.data);
    } catch (error) {
      snackBarEvent('error', getTag('unabletofetchpictures'));
    }
    try {
      const text = await getRentalConditions();
      if (text) setRentalConditions(text);
    } catch (error) {
      snackBarEvent('error', getTag('unabletofetchpictures'));
    }
    setLoading(false);
  };

  const formValidation: { [key: string]: boolean } = {
    firstName: firstName.length > 0,
    lastName: lastName.length > 0,
    email: email.length > 0,
    phoneNumber: phoneNumber.length === 17,
    paymentMethod: paymentMethod !== undefined
  };

  const formValid = () => Object.keys(formValidation).every((e) => formValidation[e]);

  const pay = async () => {
    setLoading(true);
    identify(email);
    const storage = localStorage.getItem('cart');
    if (formValid() && storage) {
      const data = {
        firstName,
        lastName,
        email,
        phoneNumber,
        bookingInfo: JSON.parse(storage),
        paymentMethod,
        locale
      };
      const storageData = {
        firstName,
        lastName,
        email,
        phoneNumber
      };
      localStorage.setItem('presonInfo', JSON.stringify(storageData));
      try {
        const res = await addBooking(data);
        const response = res.data;
        localStorage.setItem('uuid', response.uuid);
        if (paymentMethod === 'stripe') {
          navigate(`/hyyre/checkout/${response.intent}`);
        }
        if (paymentMethod === 'smartum') {
          window.location.assign(response.data.data.url);
        }
        if (paymentMethod === 'epassi') {
          navigate('/hyyre/epassi', {
            state: {
              stamp: response.uuid,
              site: response.site,
              amount: response.amount,
              rejectUrl: response.cancelUrl,
              cancelUrl: response.cancelUrl,
              returnUrl: response.successUrl,
              mac: response.mac,
              redirect: false
            }
          });
        }
      } catch (error) {
        snackBarEvent('error', getTag('somethingWentWrong'));
      }
    } else {
      snackBarEvent('error', getTag('somethingWentWrong'));
    }
    setLoading(false);
  };

  const removeReservedBooking = async (uuid: string | null) => {
    if (uuid !== null) {
      try {
        await deleteOngoing(uuid);
        localStorage.removeItem('uuid');
      } catch (error) {
        // ignore
      }
    }
  };

  useEffect(() => {
    let storage;
    const uuid = localStorage.getItem('uuid');
    const presonStorage = localStorage.getItem('presonInfo');
    removeReservedBooking(uuid);
    if (presonStorage) {
      const presonInfo: personInfo = JSON.parse(localStorage.getItem('presonInfo')!);
      setFirstName(presonInfo?.firstName);
      setLastName(presonInfo?.lastName);
      setEmail(presonInfo?.email);
      setPhoneNumber(presonInfo?.phoneNumber);
    }
    if (localStorage.getItem('cart')) {
      storage = JSON.parse(localStorage.getItem('cart')!);
      fetchData(storage);
    }
  }, []);

  return (
    <Grid className={styles.gridWrapper} container spacing={0}>
      <Grid className={styles.topDiv} item xs={12}>
        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>{getTag('shoppingCart')}</Typography>
      </Grid>
      {(items.products && items.products.length > 0) || (items.addons && items.addons.length > 0) ? (
        <>
          <Grid item xs={12} lg={6}>
            <div className={styles.inputCont}>
              <TextField
                required
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                error={!formValidation.firstName}
                sx={{ width: '80%', margin: '1vh 0' }}
                multiline
                variant='filled'
                size='small'
                label={`${getTag('firstName')}`}
              />
              <TextField
                required
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                error={!formValidation.lastName}
                sx={{ width: '80%', margin: '0.5vh 0' }}
                multiline
                variant='filled'
                size='small'
                label={`${getTag('lastName')}`}
              />
              <TextField
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!formValidation.email}
                sx={{ width: '80%', margin: '0.5vh 0' }}
                multiline
                variant='filled'
                size='small'
                label='Email'
              />
              <MuiPhoneNumber
                onChange={(e) => { setPhoneNumber(e.toString()); }}
                variant='filled'
                label={`${getTag('phone')}`}
                value={phoneNumber}
                required
                style={{ width: '80%', margin: '0.5vh 0' }}
                disableDropdown
                defaultCountry='fi'
                error={!formValidation.phoneNumber}
              />
            </div>
          </Grid>
          <Grid item xs={12} lg={6}>
            {items.products && items.products.map((o: cartItem) => (
              <Grid className={styles.productCont} container spacing={0}>
                <Grid className={styles.pictureBox} xs={2}>
                  <img className={styles.featuredPicture} src={`data:image/png;base64,${o.picture}`} alt='' />
                </Grid>
                <Grid className={styles.itemBox} item xs={7}>
                  <div>
                    <Typography sx={{ fontWeight: 'bold' }}>
                      {`${o.manufacturer} ${o.model}`}
                    </Typography>
                    <Typography className={styles.itemText}>
                      {`${getTag('quantity')} ${o.amount}`}
                    </Typography>
                    <Typography className={styles.itemText}>
                      {`${items.from} - ${items.to}`}
                    </Typography>
                  </div>
                </Grid>
                <Grid className={styles.priceBox} item xs={3}>
                  <Typography className={styles.itemText}>
                    {`${o.price}€`}
                  </Typography>
                </Grid>
              </Grid>
            ))}
            {items.addons && items.addons.map((o: any) => (
              <Grid className={styles.productCont} container spacing={0}>
                <Grid className={styles.pictureBox} xs={2}>
                  <img className={styles.featuredPicture} src={`data:image/png;base64,${o.addonPicture}`} alt='' />
                </Grid>
                <Grid className={styles.itemBox} item xs={7}>
                  <div>
                    <Typography sx={{ fontWeight: 'bold' }}>
                      {locale === 'sv' ? o.nameSv : o.nameFi}
                    </Typography>
                    <Typography className={styles.itemText}>
                      {`${getTag('quantity')} ${o.amount}`}
                    </Typography>
                    <Typography className={styles.itemText}>
                      {`${items.from} - ${items.to}`}
                    </Typography>
                  </div>
                </Grid>
                <Grid className={styles.priceBox} item xs={3}>
                  <Typography className={styles.itemText}>
                    {`${o.price}€`}
                  </Typography>
                </Grid>
              </Grid>
            ))}
            <Grid className={styles.paymentCont} item container>
              <Grid sx={{ margin: '0 0 2vh 0' }} xs={12} lg={12}>
                <Typography sx={{ fontWeight: 'bold' }}>{getTag('paymentOptions')}</Typography>
              </Grid>
              <Grid className={styles.paymentImgGrid} item xs={3}>
                <img className={styles.paymentImg} src={`data:image/png;base64,${paymentPictures?.stripe}`} alt='visa logo' />
              </Grid>
              <Grid className={styles.paymentImgGrid} item xs={3}>
                <img className={styles.paymentImg} src={`data:image/png;base64,${paymentPictures?.mastercard}`} alt='mastercard logo' />
              </Grid>
              <Grid className={styles.paymentImgGrid} item xs={3}>
                <img className={styles.paymentImg} src={`data:image/png;base64,${paymentPictures?.googlepay}`} alt='google pay logo' />
              </Grid>
              <Grid className={styles.paymentImgGrid} item xs={3}>
                <img className={styles.paymentImg} src={`data:image/png;base64,${paymentPictures?.applepay}`} alt='apple pay logo' />
              </Grid>
              <Grid className={styles.paymentImgGrid} item xs={3}>
                <img className={styles.paymentImg} src={`data:image/png;base64,${paymentPictures?.visa}`} alt='stripe logo' />
              </Grid>
              <Grid className={styles.paymentImgGrid} item xs={3}>
                <img className={styles.paymentImg} src={`data:image/png;base64,${paymentPictures?.epassi}`} alt='epassi logo' />
              </Grid>
              <Grid className={styles.paymentImgGrid} item xs={3}>
                <img className={styles.paymentImg} src='https://badges.smartum.fi/button/color.svg?width=240&height=50' alt='smartum logo' />
              </Grid>
            </Grid>
            <RadioGroup
              aria-labelledby='demo-radio-buttons-group-label'
              defaultValue={undefined}
              name='radio-buttons-payment-method'
            >
              <FormControlLabel
                value='stripe'
                onClick={() => setPaymentMethod('stripe')}
                control={<Radio />}
                label={`Stripe (Visa, MasterCard ${getTag('etc')}.)`}
              />
              <FormControlLabel value='smartum' onClick={() => setPaymentMethod('smartum')} control={<Radio />} label='Smartum' />
              <FormControlLabel value='epassi' onClick={() => setPaymentMethod('epassi')} control={<Radio />} label='Epassi' />
            </RadioGroup>
            <Grid className={styles.gridPay} xs={12}>
              <Button variant='contained' onClick={() => navigate('/hyyre')}>
                {getTag('changeBooking')}
              </Button>
              <div style={{ display: 'flex' }}>
                <Typography variant='h6' sx={{ fontWeight: 'bold', margin: '2vh 0' }}>{`${getTag('price')}`}</Typography>
                <Typography variant='h6' sx={{ color: 'green', fontWeight: 'bold', margin: '2vh 0' }}>{`: ${items.price}`}</Typography>
                <Typography variant='h6' sx={{ fontWeight: 'bold', margin: '2vh 0' }}>€</Typography>
              </div>
              <Button
                type='submit'
                sx={formValid() ? { backgroundColor: '#bad85f' } : { backgroundColor: 'lightgrey' }}
                disabled={!formValid()}
                className={styles.saveButton}
                onClick={() => setOpen(true)}
              >
                {getTag('pay')}
              </Button>
            </Grid>
          </Grid>
          <Modal
            open={open}
            onClose={handleClose}
          >
            <Box className={styles.modal}>
              <div className={styles.markdownBox}>
                <ReactMarkdown>{rentalConditions?.[locale]}</ReactMarkdown>
              </div>
              <FormControlLabel
                sx={{ width: '100%', marginBottom: '1vh' }}
                control={<Checkbox checked={accepted} onClick={() => setAccepted(!accepted)} />}
                label={`${getTag('acceptText')}*`}
              />
              <Button variant='contained' onClick={() => setOpen(false)}>
                {getTag('back')}
              </Button>
              <Button
                variant='contained'
                color='secondary'
                sx={{ color: '#fff', float: 'right' }}
                onClick={pay}
                disabled={!accepted || loading}
              >
                {getTag('confirm')}
              </Button>
            </Box>
          </Modal>
        </>
      ) : (
        <Grid className={styles.noItemDiv} item xs={12}>
          <Typography variant='h6' sx={{ fontWeight: 'bold' }}>{getTag('shoppinCartEmpty')}</Typography>
        </Grid>
      )}
    </Grid>
  );
}
